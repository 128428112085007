<template>
	<div>
		<h2>{{ $t('invoice.add_supplier') }}</h2>

		<form @submit.prevent="checkForm" id="addTiers"  autocomplete="off">
			<div class="row">
				<div class="col-12" v-if="form_message !== ''">
					<ErrorAlert :messageI18n="form_message" />
				</div>
			</div>
			<div class="row mb-2">
				<label class="col-sm-4 col-form-label">{{ $t("tiers.recherche_pappers") }}</label>
				<div class="col-sm-8">
					<e-select
						track-by="id"
						label="label"
						v-model="searchValue"
						:options="pappers_result"
						:placeholder="$t('global.rechercher')"
						:loading="loading_pappers"
						:sortable="false"
						:internal-search="false"
						preserve-search
						reset-after
						@search-change="onSearch"
						@select="searchPappers"
						ref="input"
					>
						<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_address1 }} {{ option.tiers_postalcode }} {{ option.tiers_town }})</template>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_address1 }} {{ option.tiers_postalcode }} {{ option.tiers_town }})</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
			</div>
			<div class="row">
				<label for="tiers_rs"  class="col-sm-4 col-form-label">{{ $t("tiers.rs") }} *</label>
				<div class="col-sm-8"><input type="text" id="tiers_rs" class="form-control" v-model="form.tiers_rs" @blur="checkIdenticalTierRs" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur rs') > -1 }"></div>
			</div>

			<div class="row mt-2">
				<label for="tiers_alias" class="col-sm-4 col-form-label">{{ $t("tiers.alias") }}</label>
				<div class="col-sm-8"><input type="text" id="tiers_alias" class="form-control" v-model="form.tiers_alias" required  autocomplete="chrome-off"></div>
			</div>
			
			<div class="row" v-if="mapbox">
				<div class="col-12">
					<e-address 
						:address.sync="form.tiers_address1"
						:postalCode.sync="form.tiers_postalcode"
						:city.sync="form.tiers_town"
						:country.sync="form.tiers_country"
					/>
				</div>
			</div>
			<div class="row mt-2">
				<label for="tiers_address1" class="col-sm-4 col-form-label">{{ $t("tiers.address1") }}</label>
				<div class="col-sm-8">
					<input type="text" id="tiers_address1" class="form-control" v-model="form.tiers_address1" required  autocomplete="chrome-off">
					<input type="text" id="tiers_address2" class="mt-2 form-control" v-model="form.tiers_address2" required  autocomplete="chrome-off">
				</div>
			</div>		
			<div class="row mt-2">
				<label for="tiers_postalcode" class="col-sm-4 col-form-label">{{ $t("tiers.postalcode") }}</label>
				<div class="col-sm-8">
					<input type="text" id="tiers_postalcode" class="form-control" v-if="form.tiers_postalcode" v-model="form.tiers_postalcode" required  autocomplete="chrome-off" @click="resetPostalcode">
					<SearchInputCommune v-if="isOnline && !form.tiers_postalcode" :town="0" :tiers_postalcode.sync="form.tiers_postalcode" :tiers_town.sync="form.tiers_town"/>
				</div>
			</div>	
			<div class="row mt-2">						
				<label for="tiers_town" class="col-sm-4 col-form-label">{{ $t("tiers.town") }}</label>
				<div class="col-sm-8">
					<input type="text" id="tiers_town" class="form-control" v-if="form.tiers_town" v-model="form.tiers_town" required autocomplete="chrome-off" @click="resetTown">
					<SearchInputCommune v-if="isOnline && !form.tiers_town" :town="1" :tiers_postalcode.sync="form.tiers_postalcode" :tiers_town.sync="form.tiers_town"/>
				</div>
			</div>
			<div class="row mt-2">						
				<label for="tiers_country" class="col-sm-4 col-form-label">{{ $t("tiers.country") }} </label>
				<div class="col-sm-8"><CountryInput v-model="form.tiers_country"></CountryInput></div>
			</div>
			<div class="row mt-2" v-if="hasRegions">						
				<label for="tiers_region" class="col-sm-4 col-form-label">{{ $t("tiers.region") }}</label>
				<div class="col-sm-8">							
					<e-select
						id="tiers_region"
						v-model="form.tiers_region"
						:options="regions[form.tiers_country]"
						:allow-empty="true"
						:show-labels="true"
					/>
				</div>
			</div>

			<div class="row mt-2">
				<label for="tiers_mail" class="col-sm-4 col-form-label">{{ $t("tiers.email") }}</label>
				<div class="col-sm-8">	
					<input type="email" id="tiers_mail" class="form-control" v-model="form.tiers_mail"  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur mail') > -1 }">
				</div>
			</div>

			<div v-if="form.tiers_country == 75" class="row mt-2">
				<label for="tiers_siret" class="col-sm-4 col-form-label">{{ $t("tiers.siret") }}</label>
				<div class="col-sm-8">	
					<input type="email" id="tiers_siret" class="form-control" v-model="form.tiers_siret"  autocomplete="chrome-off">
				</div>
			</div>

			<div v-if="form.tiers_country == 75" class="row mt-2">
				<label for="tiers_siren" class="col-sm-4 col-form-label">{{ $t("tiers.siren") }}</label>
				<div class="col-sm-8">	
					<input type="email" id="tiers_siren" class="form-control" v-model="form.tiers_siren"  autocomplete="chrome-off">
				</div>
			</div>

			<div class="row mt-2">
				<label for="tiers_vat_number" class="col-sm-4 col-form-label">{{ $t("tiers.num_tva") }}</label>
				<div class="col-sm-8">	
					<input type="email" id="tiers_vat_number" class="form-control" v-model="form.tiers_vat_number"  autocomplete="chrome-off" @keyup="checkVATFormat">
				</div>

				<div class="col-sm-4"></div>
				<div class="col-sm-8">	
	                <div v-if="isVatEligible && form.tiers_vat_number != '' && !vat_valid">
	                    <b-alert show variant="warning" class="mt-2">
	                        <font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/>
	                        {{ $t('tiers.invalid_vat') }}
	                    </b-alert>
	                </div>
	            </div>
			</div>

			<div class="row mt-2">
				<label for="tiers_rib" class="col-sm-4 col-form-label">{{ $t("tiers.rib") }}</label>
				<div class="col-sm-8">	
					<input type="email" id="tiers_rib" class="form-control" v-model="form.tiers_rib"  autocomplete="chrome-off">
				</div>
			</div>

			<div class="row mt-2">
				<label for="tiers_lang" class="col-sm-4 col-form-label">{{ $t("tiers.lang") }}</label>
				<div class="col-sm-8">
					<LocaleInput id="tiers_lang" v-model="form.tiers_lang" />
				</div>
			</div>
			<div class="text-center mt-2">
				<b-button variant="primary" @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }} <font-awesome-icon :icon="['fal', 'user-plus']"/></b-button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
import Vue from 'vue'
	
	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import Tiers from '@/mixins/Tiers.js'
    import Countries from '@/assets/lang/countries/countries'
    import cloneDeep from 'lodash/cloneDeep'
	import Shutter from "@/mixins/Shutter.js"
	import Common from '@/assets/js/common.js'
	import Regions from '@/assets/lang/countries/regions'
	import _debounce from 'lodash/debounce'

	export default {
		name: "AddTiers",
		props: ['tiers_info'],
		mixins: [Navigation, Tools, Tiers, Shutter],
		data () {
			return {
				processing: false,
				error: [],
                form_message: "",
                tier_already_exists: false,
				form: {},
				countries: {},
				country_choosen: false,
				civilities: Common.getAllCivilities(),
				regionWhitelist: [104],
				regions: Regions,
                pappers_result: [],
				loading_pappers: false,
				debouncedSearch: _debounce(this.search, 300),
				searchValue: '',
				default_form: {
					tiers_rs: '',
					tiers_firstname: '',
					tiers_lastname: '',
					tiers_civility: this.getTrad('civility.m'),
					tiers_mail: '',
					tiers_alias: '',
					tiers_address1: '',
					tiers_address2: '',
					tiers_postalcode: '',
					tiers_town: '',
					tiers_country: this.getConfig('country', '75'),
					tiers_website: '',
					tiers_entity: false,
					tiers_moral: true,
					tiers_currency: 'EUR',
					tiers_lang: 'fr',
					tiers_region: '',
					phones: [
						{
							phone_indicatif: '+33',
							phone_numero: '',
							phone_type: this.getTrad('tiers.phone_type.fixe'),
							phone_country: "FRA",
						},
						{
							phone_indicatif: '+33',
							phone_numero: '',
							phone_type: this.getTrad('tiers.phone_type.portable'),
							phone_country: "FRA",
						}
					]
				},
				mapbox: null,
				vat_valid: true
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.mapbox = this.getMapBoxKey()

				this.tier_already_exists = false
				this.form_message = ""
				this.form = this.default_form

				if(this.tiers_info) {
					this.form.tiers_rs = this.tiers_info.tiers_rs
					this.form.tiers_address1 = this.tiers_info.tiers_address1
					this.form.tiers_siret = this.tiers_info.tiers_siret
					this.form.tiers_siren = this.tiers_info.tiers_siren
					this.form.tiers_vat_number = this.tiers_info.tiers_vat_number
					this.form.tiers_rib = this.tiers_info.tiers_rib

					if(this.tiers_info.tiers_vat_number) {
						this.checkVATFormat()
					}
				}

				this.countries = Countries[Vue.i18n.locale()].label
			},
			checkForm: async function () {
				if(!this.processing) {
					this.processing = true

					this.form_message = ""
					this.error = []

					if(this.form.tiers_moral == true) {
						if(!this.form.tiers_rs) { 
							this.error.push("Erreur rs") 
						}
					}
					else {
						// if(!this.form.tiers_firstname){
						// 	this.error.push("Erreur prénom") 
						// }
						if(!this.form.tiers_lastname){
							this.error.push("Erreur nom") 
						}
						if(!this.form.tiers_civility){
							this.error.push("Erreur civilité") 
						}
					}

					if(this.form.tiers_mail && !this.form.tiers_mail.match(this.regex_email)) {
						this.error.push('Erreur mail')
					}

					if(!this.form.tiers_country) {
						this.error.push("Erreur pays")
					}

					if(this.error.length == 0) {
						this.form.tiers_civility = Common.manageCivilities(this.form.tiers_civility)

	                    const params = cloneDeep(this.form)
						try {
							let tiers_id = await this.addTiersWithRIB(params)
							
                        	await this.$sync.force(true)
							tiers_id = this.$sync.replaceWithReplicated('tiers', tiers_id)

							this.processing = false
							this.ok(tiers_id)
			                this.shutterPanel().close('ajout-tiers')
						}
						catch (e) {
							if (typeof(e) === 'string') {
								this.form_message = e
								this.failureToast(e)
							}
							else {
								console.error(e)
							}

							return null
						}
					} else {
						this.processing = false
						this.form_message = "formulaire.erreur_champs_non_remplis"
					}
				}
            },

            /* Vérifie si un tiers de la même raison sociale (ou de la même combinaison nom/prenom si pas personne morale) existe déjà */
            async checkIdenticalTierRs() {
				this.tier_already_exists = false

				const rs = this.form.tiers_moral === true ? this.form.tiers_rs : `${this.form.tiers_lastname} ${this.form.tiers_firstname}`
				if(!rs) {
					return false
				}

				const count = await this.tierExists(rs, this.form.tiers_id)
				this.tier_already_exists = count > 0
            },

			addPhoneNumber() {
				this.form.phones.push({
					phone_indicatif: '+33',
					phone_numero: '',
					phone_type: this.getTrad('tiers.phone_type.fixe'),
					phone_country: 'FRA',
				})
			},
			
			resetTown() {
				this.form.tiers_town = ''
			},

			resetPostalcode() {
				this.form.tiers_postalcode = ''
			},

			onSearch(search_val) {
				this.debouncedSearch(search_val)
			},
			async search(search_val) {
				if(!search_val) {
					return
				}
				let type = "RS"
				this.loading_pappers = true

				if (!isNaN(search_val)){
					if(search_val.length == 9){
						type = "SIREN"
					}else if(search_val.length == 14){
						type = "SIRET"
					}
				}

				const pappers = this.searchAPIPappers(search_val, type, false, false)
					.then(res => {
						if(res.resultats) {

							this.pappers_result = res.resultats.map(tiers => {
								return {
									tiers_rs: tiers.nom_entreprise,
									tiers_postalcode: tiers.siege.code_postal,
									tiers_address1: tiers.siege.adresse_ligne_1,
									tiers_address2: tiers.siege.adresse_ligne_2,
									tiers_town: tiers.siege.ville,
									tiers_siret: tiers.siege.siret,
									tiers_siren: tiers.siren,
									tiers_vat_number: tiers.numero_tva_intracommunautaire,
								}
							})
						}
					})

				// Ajout des résultats au e-select
				await Promise.all([pappers])

				this.loading_pappers = false
			},
			async searchPappers(option) {
				this.form.tiers_rs = option.tiers_rs
				this.form.tiers_address2 = option.tiers_address2
				this.form.tiers_address1 = option.tiers_address1
				this.form.tiers_town = option.tiers_town
				this.form.tiers_postalcode = option.tiers_postalcode
				this.form.tiers_siret = option.tiers_siret
				this.form.tiers_siren = option.tiers_siren
				const vat = option.tiers_siret ? await this.getVATFromPappers(option.tiers_siret) : null
				this.form.tiers_vat_number = vat ?? this.form.tiers_vat_number
				const rne = option.tiers_siret ? await this.getRNEFromPappers(option.tiers_siret) : null
                this.form.tiers_statut_rne = rne ?? this.form.tiers_statut_rne
			},

	        checkVATFormat() {
	        	if(this.isVatEligible && this.form.tiers_vat_number) {
		            this.form.tiers_vat_number = this.form.tiers_vat_number.replace(/\W/g, '')
		            this.vat_valid = this.checkVAT(this.form.tiers_vat_number, this.form.tiers_country)
		        }
	        },
        },
		computed : {
			isOnline() {
				return window.navigator.onLine
			},
			hasRegions() {
				return this.regionWhitelist.includes(parseInt(this.form.tiers_country))
			},
	        isVatEligible(){
	            if(!this.form.tiers_country) return false
	            // Si hors UE ne pas afficher le champ numéro de TVA
	            const countryRegexes = this.getCountryVatRegex()
	            if (countryRegexes[this.form.tiers_country]) return true
	            return false
	        }
		},
		components: {
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
            CountryInput : () => import('GroomyRoot/components/Inputs/CountryInput'),
            LocaleInput : () => import('@/components/Inputs/LocaleInput'),
			SearchInputCommune : () => import('@/components/Inputs/SearchInputCommune')
		}
	}
</script>